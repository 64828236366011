import React from "react";
// import _ from "lodash";
import axios from "axios";


import { AssetsPopup } from "./AssetsPopup";
import Loading from "../Miscellaneous/Loading";

import { Table, TableHead, TableRow, TableCell, TableBody, TablePagination, MenuItem, Menu, Checkbox, TableSortLabel, Typography } from "@material-ui/core";
import moment from 'moment';

import ScrollTopLeft from "../Miscellaneous/scrollTopLeft";
const hiddenColsDefault = [
    'vendor_budget',
    'variable_cpl',
    'send_leads_to_email_system',
    'add_incoming_leads',
    'workflow_id',
    'campaign_name',
    'interest_name',
    'medium',
    'type_id',
    'content_name',
    'keyword',
    'publisher_name',
    'affiliate_name',
    'guru_name',
    'vendor_budget',
    'projected_margin',
    'client_marketing_costs',
    'client_campaign',
    'realized_margin'
];

const types = {
    'dynamic-webinar': 'Dynamic Webinar Date & Time Widget',
    'lead-capture': 'Lead Capture Form',
    'live-webinar': 'Live Webinar Registration/Redirect Form',
    'odw-webinar': 'ODW Webinar Registration/Redirect Form'
};

class FormBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: false,
            activeRow: {},
            defaultHiddenColumns: [...hiddenColsDefault],
            //columnsAnchorEl: null,
            //showAssetsPopup: false,
            loading: true,
            //filterParams: {},
            allColumns: [
                {
                    "label": 'Widget ID',
                    "fieldName": 'widget_id',
                    "minWidth": 50
                },
                {
                    "label": 'Widget Name',
                    "fieldName": 'name',
                    "minWidth": 500,
                    "onClick": "goToWidget"
                },
                {
                    "label": 'Type',
                    "fieldName": 'type',
                    "minWidth": 200,
                    'map': types
                },
                {
                    "label": 'Offer',
                    "fieldName": 'offer_name',
                    "minWidth": 200
                },
                {
                    "label": 'Client',
                    "fieldName": 'client_name',
                    "minWidth": 200
                }
            ],
            pagination: {
                size: 25,
                page: 0,
                totalCount: 0
            },
            sort: {
                asc: false,
                column: 'widget_id'
            }
        };
    }

    componentDidMount() {
        this.setState({ loading: true }, this.loadData);
    }

    async loadData() {
        this.setState({
            ...this.state,
            loading: true
        })
        const { data } = await axios.get(
            process.env.SERVER_ENDPOINT + `/widgets?page=${this.state.pagination.page + 1}&size=${this.state.pagination.size}&orderBy=${this.state.sort.column}&orderDir=${this.state.sort.asc ? 'asc' : 'desc'}`
        );
        data.meta.page--;

        this.setState({
            data: data.data,
            pagination: data.meta,
            loading: false,
        });
    }

    sortBy(col) {
        let direction = this.state.sort.asc;
        let column = this.state.sort.column;

        if (col === this.state.sort.column) {
            direction = !this.state.sort.asc;
        } else {
            column = col;
        }

        this.setState({
            ...this.state,
            sort: {
                column: column,
                asc: direction
            }
        }, this.loadData);
    }

    changeRowsCount(event, count) {
        let pagination = { ...this.state.pagination };
        pagination.page = 0;
        pagination.size = parseInt(count.key);
        this.setState({
            ...this.state,
            pagination: pagination
        }, this.loadData);
    }

    changePage(event, page) {
        let pagination = { ...this.state.pagination };
        pagination.page = page;

        this.setState({
            ...this.state,
            pagination: pagination
        }, this.loadData);
    }

    isColDisabled(col) {
        return this.state.defaultHiddenColumns.indexOf(col) > -1;
    }

    toggleColumn(col, value) {
        let defCols = [...this.state.defaultHiddenColumns];
        if (value) {
            defCols.splice(defCols.indexOf(col), 1);
        } else {
            defCols.unshift(col);
        }
        this.setState({
            ...this.state,
            defaultHiddenColumns: defCols
        });

        localStorage.setItem(`hiddenColumns${document.location.pathname}`, JSON.stringify(defCols));
    }

    switchColPreset(param) {
        let hiddenCols = [];
        if (param === 'default') {
            hiddenCols = [...hiddenColsDefault];
        } else if (param === 'none') {
            hiddenCols = this.state.allColumns.map(col => col.fieldName);
        }

        localStorage.setItem(`hiddenColumns${document.location.pathname}`, JSON.stringify(hiddenCols));

        this.setState({
            ...this.state,
            defaultHiddenColumns: hiddenCols
        })
    }

    toggleColsSettings(event) {
        this.setState({
            ...this.state,
            columnsAnchorEl: event.currentTarget,
            colsSettingsOpened: !this.state.colsSettingsOpened
        })
    }

    format(value, type) {
        if (!type) return value;
        switch (type) {
            case 'date':
                return moment(value).format('MM/DD/YYYY');

            case 'month':
                return moment().month(value.toString()).add('months', -1).format('MMMM');
            case 'money':
                return new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(value);

            default:
                return value;
        }
    }

    goToWidget(itm) {
        window.location.href = `/media-planner/form?id=${itm.widget_id}`;
    }

    getAsset(data) {
        this.setState({
            showAssetsPopup: true,
            activeRow: data,
        })
    }

    colClickMethod(params, col) {
        eval(`this.${col.onClick}`).call(this, params);
    }

    handleSelect(params) {
        this.setState({
            ...this.state,
            filterParams: params
        }, this.loadData);
    }

    render() {
        const { data } = this.state;
        let headers = this.state.allColumns;
        return (
            <>
                <Table className="reports__table media__planner" stickyHeader>
                    <TableHead>
                        <TableRow className="reports__header-sticky">
                            <TableCell key={`col_title`} colSpan={headers.length}>
                                <div className="reports__header_container" style={{ minWidth: '930px' }}>
                                    <h3>Form & Widget Builder</h3>

                                    {/* <MediaBuysFilters onSelect={params => this.handleSelect(params) } />
                                <button className="colsSettingsBtn" aria-haspopup="true" onClick={event => this.toggleColsSettings(event)}>
                                    <img alt="" src="/icons/svg/settings.svg" />
                                </button> */}

                                    <a className="btn createNew__btn" href="/media-planner/form">
                                        Create New Widget
                                </a>

                                    <Menu
                                        id="columns-menu"
                                        keepMounted
                                        anchorEl={this.state.columnsAnchorEl}
                                        open={Boolean(this.state.colsSettingsOpened)}
                                        onClose={this.toggleColsSettings.bind(this)}
                                    >
                                        <Typography style={{ height: '30px' }}>
                                            <span style={{ fontSize: '14px', float: 'right' }}>
                                                Select:
                                            <button onClick={() => this.switchColPreset('all')} className="filter_select-allnone">All</button>
                                                <button onClick={() => this.switchColPreset('none')} className="filter_select-allnone">None</button>
                                                <button onClick={() => this.switchColPreset('default')} className="filter_select-allnone">Default</button>
                                            </span>
                                        </Typography>

                                        {headers.map((d, j) => d ? (
                                            d.type !== 'template' ?
                                                <MenuItem onClick={() => this.toggleColumn(d.fieldName, (this.state.defaultHiddenColumns.indexOf(d.fieldName) > -1))} key={`select_col_key_${j}`} value={d.fieldName}>
                                                    <Checkbox checked={!this.isColDisabled(d.fieldName)} value={d.fieldName} />
                                                    {d.label}
                                                </MenuItem>
                                                : null
                                        ) : null)}
                                    </Menu>
                                    {!this.state.loading ?
                                        <TablePagination
                                            style={{
                                                position: 'absolute',
                                                right: '190px',
                                                top: 0
                                            }}
                                            rowsPerPageOptions={[15, 25, 50, 100]}
                                            component="div"
                                            count={this.state.pagination.totalCount}
                                            rowsPerPage={this.state.pagination.size}
                                            page={this.state.pagination.page}
                                            onChangePage={this.changePage.bind(this)}
                                            onChangeRowsPerPage={this.changeRowsCount.bind(this)}
                                        />
                                        : null}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {headers.map((col, i) => (
                                !this.isColDisabled(col.fieldName) && !this.state.loading ?
                                    <TableCell className={`results__col_${i}`} key={`col_${i}`}>
                                        <TableSortLabel
                                            active={this.state.sort.column === col.fieldName}
                                            direction={this.state.sort.asc ? 'asc' : 'desc'}
                                            onClick={() => this.sortBy(col.fieldName)}
                                        >
                                            {col.label}
                                        </TableSortLabel>
                                    </TableCell>
                                    : null
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {!data || this.state.loading ? (
                            <TableRow style={{ backgroundColor: '#fff' }}>
                                <TableCell colSpan={10}>
                                    <Loading />
                                </TableCell>
                            </TableRow>

                        ) : (
                                this.state.data.map((row, i) => (
                                    <TableRow key={i}>
                                        {headers.map((col, j) => (
                                            !this.isColDisabled(col.fieldName) ?
                                                <TableCell onClick={col.onClick ? () => this.colClickMethod(row, col) : null} style={{ textDecoration: col.onClick ? 'underline' : 'none', cursor: col.onClick ? 'pointer' : 'inherit' }} key={`_col_row_${j}_${i}`}>
                                                    {col.default ? col.default :
                                                        col.map ? col.map[row[col.fieldName]] :
                                                            row[col.fieldName] && row[col.fieldName] !== null && row[col.fieldName] !== '' ? this.format(row[col.fieldName], col.type) :
                                                                '-'}
                                                </TableCell>
                                                : null
                                        ))}
                                    </TableRow>
                                ))
                            )}
                    </TableBody>
                </Table>
                <ScrollTopLeft />
            </>
        );
    }
}

export default FormBuilder;
